@import "../library/colors";

/* CARDS
=========================== */

.card {
    padding: 30px;
    border-radius: 0;
    .img-wrapper {
        height: 273px;
        overflow: hidden;
        background: black;
    }
}

.card-white {
    background-color: #fff;
}

.card-primary {
    background-color: $primary;
}

.governance-board-member {
    .card {
        padding: 0;
        min-height: 300px;
    }
}

.load-more-container{
    .load-more-card{
        display: block;
        margin: 0 auto;
    }
}

.partner-card {
    .card-img-top {
        width: auto;
        max-width: 100%;
    }
}

