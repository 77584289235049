@import "../library/colors";
@import "../library/responsive";

 @media only screen  and (max-width: $screen-sm ) {
    .register-form-page {
        padding-left: 1rem !important;
            padding-right: 1rem !important;
    }
 }

 @media only screen  and (max-width: $screen-md ) {
     .input-group {
         padding-bottom: 10px;
         }
     .category-select {
         padding-left: 0 !important;
         padding-right: 0 !important;
     }
 }



/* FORMS
=========================== */
.form-group {
     input {
         border-top:0;
         border-left:0;
         border-right:0;
         border-radius: 0;

    }

}

.form-control {
    font-size: 16px; //ios form autozoom fix

    @include breakpoint(sm) {
        font-size: 14px;
    }
}

.input-group {
    border-radius: 0;
}

/* CUSTOM CHECKBOX */

.checkbox-custom {
    position: relative;
    text-align: left;

    label {
        display: inline-block;
        width: calc(100% - 22px);
        padding-left: 35px;
    }

    input[type=checkbox] {
        visibility: hidden;
        display: inline;
    }
    label {
        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            width: 22px;
            height: 22px;
            left: 0;
            top: 0;
            cursor: pointer;
            background-color: $white;
            transition: all 500ms ease;
            border-radius: 0;
        }
        &:before {
            border: 2px solid $grey-bg;
        }

        &:after {
            @include fa-icon('\f00c');
            color: $white;
            text-align: center;
            transform: scale(0);
            transition: all 300ms ease;
            background-color: transparent;
        }
        &:active:before {
            background-color: darken($grey-light-bg, 20%);
        }
        &:hover:before {
            background-color: darken($grey-light-bg, 10%);
        }
    }
    input[type=checkbox]:checked + label {
        &:before {
            background-color: $primary;
            border-color: $primary;
        }
        &:after {
            transform: scale(1);
        }
        &:active:before {
            background-color: darken($primary, 20%);
            border-color: darken($primary, 20%);
        }
        &:hover:before {
            background-color: darken($primary, 10%);
            border-color: darken($primary, 10%);
        }
    }
    @include breakpoint(md) {
        label {
            padding-left: 38px;
        }
    }
}

/* CUSTOM RADIO */

.radio-custom {
    position: relative;

    input[type=radio] {
        visibility: hidden;
        display: inline;
    }

    label {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            left: 0;
            top: 2px;
            cursor: pointer;
            background-color: $grey-light-bg;
            transition: all 500ms ease;
            border-radius: 30px;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            left: 4px;
            top: 6px;
            background-color: $primary;
            border-radius: 30px;
            transform: scale(0);
        }
        &:active:before {
            background-color: darken($grey-light-bg, 20%);
        }
        &:hover:before {
            background-color: darken($grey-light-bg, 10%);
        }
    }
    input[type=radio]:checked + label {
        &:after {
            transform: scale(1);
        }
    }
    @include breakpoint(md) {
        label {
            padding-left: 10px;
        }
    }
}

/* CUSTOM SELECT */

.select-custom {
    position: relative;
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    &:before {
        @include fa-icon('\f107');
        position: absolute;
        top: -32px;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 20px;
        font-size: 24px;
        color: $border;
        pointer-events: none;
    }
    @include breakpoint(lg) {
        &:before {
            height: 25px;
        }
    }
}

/* REMOVE YELLOW AUTOFILL COLOR  */

@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

.register-form-page {
    padding: 5rem;
    .register-form {
        padding: 3rem;
        max-width: 500px;
        width: 100%;
        margin: auto;
        h1 {
            text-align: center;
        }
        form {
            padding-top: 2rem;
            label {
                font-weight: 600;
            }
            .form-control {
                background-color: $grey-light-bg;
                border: 1.2px solid black;
            }
            .btn {
                margin-left: auto;
                margin-right: auto;
                display: block;
                margin-top: 2rem;
            }
            .login-link {
                text-align: center;
                display: block;
                padding-top: 1rem;
                color: #243746!important;
            }
            .login-link:hover{
                color: #ffc844!important;
                text-decoration:none;
            }

        }
    }
}

.events-form {
    .input-group-text {
        border-radius: 0;
    }
    .category-select {
        padding-left:5px;
        padding-right: 5px;
        .input-group-prepend {
            .input-group-text {
                padding: 9px;
            }
        }
        .select-custom {
            padding:6px;
            width: calc(100% - 37px);
            margin-left:1px;
            border-color: #CED4DA;
            font-size:16px;
         }
    }

}


