@import "../library/colors";
@import "../library/responsive";
$photo-height:600px;

 @media only screen  and (max-width: $screen-sm ) {
     .component-text-image-max-container {
         flex-direction: column-reverse !important;
         .component-text {
             padding: 3rem 3% !important;
             align-self: flex-start !important;
             max-width: 100% !important;

         }
         .image {
             img {
                 width: 100% !important;
                 padding: 0 3%;
             }
         }

     }

 }


 @media only screen and (min-width: $screen-sm ) and (max-width: $screen-md ) {
     .component-text-image-max-container {
         flex-direction: column-reverse !important;
         .component-text {
             padding: 3rem 14% !important;
             align-self: flex-start !important;
             max-width: 100% !important;
         }
         .image {
             img {
                 width: 100% !important;
                 padding: 0 14%;
             }
         }

     }

 }



.component-text-image-max-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    &.image-left {
        flex-direction: row-reverse;
        .component-text {
            padding-left: 5%;
            padding-right: 12%;
        }
    }
    &.image-right {
        .component-text {
            flex-direction: row;
        padding-left: 12%;
        padding-right: 5%;
        }
    }


    .component-text {
        max-width: 50%;
        align-self: center;

        h2 {
                font-family: 'Winnipeg2', Fallback, serif;
                font-weight: 600;
        }
        .text {
                font-size: 1rem;
                margin-top: 2rem;
                margin-bottom: 2rem;
        }

    }
    .image {
            img {
                object-fit: cover;
                width: 45vw;
                height: 45vw;
            }
    }
}
