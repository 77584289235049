@import "../library/responsive";
@import "../library/colors";

/* Carousel home page
=========================== */
.carousel-item-img{
    height:500px;
    background-size: cover;
    background-position: center;
    filter: contrast(.5) saturate(1.4);
}

$carousel-height:800px;
 @media only screen and (max-width: $screen-sm) {
     .carousel-homepage {

        .carousel-item-img{
            height:250px;
        }



         .carousel-caption {
             top: 10% !important;
             left: 0 !important;;
             max-width: 75% !important;
             padding-left: 1rem !important;



             p{
                overflow: hidden;
                text-overflow: ellipsis;
                padding-bottom: 1rem;
                margin: 0;
             }

             p:first-of-type {
                 font-size: 1.5rem !important;
             }

             p:last-of-type {
                 font-size: 1rem !important;
             }

             .btn-primary {
                background-color: #ffc844!important;
                color: #243746!important;
                border-radius: 0;
                padding:0.5rem !important;
            }

         }

         .carousel-control-next,
         .carousel-control-prev {
             right: 3% !important;
             padding: .6rem .5rem .5rem .6rem !important;
         }
     }
 }

   @media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {

       .carousel-homepage {

        .carousel-item-img{
            height: 365px;
        }



           .carousel-caption {
               top: 30% !important;
               max-width: 65% !important;
               padding-left: 0 !important;

               p{
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-bottom: 1rem;
                margin: 0;
                font-size:1.5rem;
               }

               p:first-of-type {
                   font-size: 2.5rem !important;
               }

               p:last-of-type {
                   font-size: 1.5rem !important;
               }

           }
           .carousel-control-next,
           .carousel-control-prev {
               right: 3% !important;
           }
       }
    }
    @media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
        .carousel-homepage {
            .carousel-item-img{
                height: 450px;
            }
            .carousel-caption {
                top: 30%;
                left: 0;
                max-width: 65%;
                p{
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    text-overflow: ellipsis;
                      padding-bottom: 1rem;
                    margin: 0;
                    font-size: 1.5rem;
                   }
                p:first-of-type {
                    font-size: 2.5rem !important;
                }
                p:last-of-type {
                    font-size: 1.5rem !important;
                }
            }
            .carousel-control-next,
            .carousel-control-prev {
                right: 3%;
            }
        }
    }

.carousel-homepage {
    max-height: $carousel-height;
    .carousel-cover {
        position: absolute;
        clip-path: polygon(76% 0, 100% 0, 100% 100%, 27% 100%);
        background-color: rgb(170, 206, 250);
        z-index: 1;
        right: 0;
        top: 0;
        width: 45%;
        opacity: 0.5;
        height: 100%;
        max-height: $carousel-height;
    }
    .carousel-cover1 {
        @extend .carousel-cover;
        width: 55%;
        clip-path: polygon(70% 0, 100% 0, 100% 100%, 27% 100%);
    }
    .carousel-inner {
        max-height: $carousel-height;
    }
    .carousel-control-next,
    .carousel-control-prev {
        top: 70% !important;
        z-index: 3 !important;
        border: 2px solid white;
        right: 12% !important;
        color: white !important;
        bottom: auto !important;
        display: block !important;
        width: auto !important;
        padding: .9rem .7rem .6rem .7rem;
    }

    .carousel-control-prev {
    margin-right: 4rem;
    left: auto !important;
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        width: 1.8rem;
        height: 1.5rem;
    }



    .carousel-item {
        position: relative;
        .carousel-caption {
            text-align: left !important;
            max-width: 50%;
            top: 20%;
            left:10%;


            p.carousel-title {
            font-family: 'Winnipeg2', Fallback, serif;
            color: $primary;
            font-size: 3.5rem;
            font-weight: 600;
            }
            p.carousel-text {
            font-family: 'Winnipeg2', Fallback, serif;
            color: $white;
            font-size: 1.5rem;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            }
        }
    }


}

@media only screen and (max-width: 902px) {
    .carousel-homepage .carousel-item .carousel-caption {

    }
}

@media only screen and (max-width: 768px) {
    .carousel-homepage .carousel-caption {
        top: 10% !important;
    }
}

@media (min-width: $screen-lg) {


     .carousel-homepage {
         .carousel-caption {

            .carousel-item-img{
               height: 625px;
            }


             p{
                overflow: hidden;
                text-overflow: ellipsis;
                padding-bottom: 1rem;
                margin: 0;
             }

             p:first-of-type {
                 font-size: 3rem !important;
             }

             p:last-of-type {
                 font-size: 2.5rem !important;
             }

            }

         }

    #header-img {
        h1 {
            margin-left: 10%;
        }
    }


}

@media only screen and (max-width: 1116px) {
    .events-section {
        .component-events {
            //margin-left: -48%;
            .carousel-homepage .carousel-inner .events {
                width: auto !important;
            }
        }
    }
}

@media only screen and (max-width: 996px) {
    .events-section {
        .component-image {
            display: none;
        }
    }
}

@media only screen and (max-width: 680px) {
    .news.bg-white {
        display: none;
    }
    .events-section {
        display: block !important;
        .component-image {
            .image {
                display: none !important;
            }
        }
        .component-events {
            margin-left: 0 !important;
            max-width: 100% !important;

            .carousel-homepage {
                .carousel-inner {
                    .events {
                        height: auto !important;
                        padding: 25px !important;
                        min-height: 500px;
                        .event-date {
                            margin-top: 50px;
                        }
                    }
                }
            }
        }
    }
}
