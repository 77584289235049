/* HEADER */
@import "library/colors";
@import "library/responsive";


#navbarCollpaseContent a {
    font-size: 1.5rem !important;
}

#navbarCollpaseContent .search-form {
    height: 2rem !important;
}


@media only screen and (max-width: $screen-sm) {

    .navbar-collapse a.nav-link.search-button:hover {
        background: initial !important;
        color: $black !important;
    }

    #header-img {
        min-height: 300px !important;
    }
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }
    nav {
        .nav-primary {

            ul {
                align-items: center;

                li {
                    margin-left: 0 !important;
                }
            }

        }

        .nav-secondary {
            margin-left: 0 !important;

            ul {
                flex-direction: row !important;
                justify-content: space-between;
            }

            input {
                transition: 0.3s ease;
                border: none;
                border-bottom: 1px solid $primary;
                border-radius: 4px;
                padding-left: 2px;
                height: 2.5rem;

            }
        }

        .submit-button {
            background-color: transparent;
            border: none;
            font-size: 1.5rem !important;
            font-weight: 600;
            margin-left: 5px;
        }

    }

}


@media only screen and (min-width: $screen-sm) and (max-width: $screen-lg) {

    #navbarCollpaseContent a {
        font-size: 1.5rem !important;
    }

    nav {
        .nav-primary {
            margin-bottom: 1rem;

            ul {
                flex-direction: row !important;

                li {
                    margin-left: 2rem;
                }

                li:first-of-type {
                    margin-left: 0;
                }
            }

        }

        .nav-secondary {
            margin-left: 0 !important;
            margin-bottom: 2rem;

            ul {
                flex-direction: row !important;
                justify-content: space-between;
            }

        }

    }
}

#header-img {
    display: flex;

    .container {
        margin-left: -1px;
        background: rgba(30, 76, 133, 0.6);
        max-width: 100%;

        .title-wrapper {
            max-width: 1200px;
            width: 100%;
            margin: auto;
        }
    }
}

nav {
    color: $brand-text-color;

    ul {
        li {
            text-transform: capitalize;
        }
    }

    .nav-link {
        img {
            width: 100%;
            max-width: 235px;
            height: 38px;
        }
    }

    button {
        &:focus {
            outline: 0;
        }
    }

    .navbar-toggler {
        border-color: $primary !important;
        border-radius: 0;
    }

    .nav-secondary {
        margin-left: auto;
        min-height: 40px;

        .nav-link {
            font-size: 1rem !important;
            display: inline-block;
            color: $black;
        }

        .fa {
            display: inline-block;
            padding-right: 5px;
        }

        .search-form {
            display: inline-block;

            input {
                transition: 0.3s ease;
                border: none;
                border-bottom: 1px solid $primary;
                padding-left: 10px;
                height: 2.5rem;
                background: #fff;
                color: #1b2a37;
                width: calc(100% - 90px);
                margin-left: 10px;
                margin-right: 10px;

            }
        }

        .submit-button {
            background-color: transparent;
            border: none;
            font-size: 1.5rem !important;
            font-weight: 600;
            margin-left: 5px;
        }
    }

    .nav-primary {
        .nav-link {
            font-size: 1rem !important;
            font-weight: 600;
            padding-bottom: 0.2rem;
        }

        @media (min-width: 992px) {
            .selected, .ancestor {
                border-bottom: 2px solid $primary;
            }

            .nav-item {
                &:not(.selected) {
                    border-bottom: 2px solid $white;
                }

                &.ancestor {
                    border-bottom: 2px solid $primary;
                }
            }

            .children-level {
                .selected {
                    border-bottom: initial;
                    background-color: $primary;

                    &:hover {
                        background-color: $primary !important;
                    }
                }
            }
        }


        .fa {
            color: $primary;
            padding: 8px;
            font-weight: 600;
            font-size: 16px;
        }

        .children-level {
            position: absolute;
            z-index: 11;
            background-color: $white;
            padding: 0 0 0.5rem 0;
            @media (min-width: 992px) {
                top: calc(100% + 2px);
            }

            .nav-item {
                list-style-type: none;
                margin-right: initial !important;
                margin-left: initial !important;
                padding: 0 16px;
                margin-top: 8px;
                border: initial;

                &:hover, &:focus {
                    background-color: $grey-light-bg;
                }

                .nav-link {
                    border-bottom: initial;
                }
            }
        }
    }
}

.navbar-light .navbar-nav .nav-link {
    color: $brand-text-color;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: $secondary;
}

#top-nav-main {
    ul.navbar-nav {
        .nav-link {
            font-size: 1rem;
            white-space: nowrap;
        }
    }

    nav.navbar {
        img[alt="logo"] {
            width: 235px;
            margin-top: 15px;
        }
    }
}

@media (min-width: 1200px) {
    #top-nav-main.container {
        max-width: 1230px;
    }
}

@media (max-width: 991px) {
    nav .nav-link img {
        max-width: 180px;
    }

    .container {
        max-width: 100%;
    }
    #navbarCollpaseContent.navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        -ms-flex-align: center;
        align-items: center;
        position: fixed;
        height: 100%;
        background: #ffc844;
        z-index: 11;
        top: 0;
        left: 0;
        padding: 24px;
        box-shadow: 9px 0 9px -6px rgba(0, 0, 0, 0.75);
        overflow-y: scroll;
        width: 100%;
        @media (min-width: 576px) {
            width: calc(100% - 97px);
            max-width: 450px;
        }

        .nav-primary {
            margin-left: 0;

            ul {
                align-items: initial;
                flex-direction: column !important;
                width: 100%;
                padding: 0 0 8px 0;

                li {
                    margin-left: 0;
                    font-size: 14px !important;

                    &.selected, &.ancestor {
                        .nav-link {
                            border-bottom: 3px solid #000;
                        }

                        .children-level {
                            .nav-link {
                                border-bottom: initial;
                            }
                        }
                    }
                }
            }

            ul > li {
                a {
                    padding: 12px 16px;
                    border-bottom: initial;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 1.1rem !important;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2rem;
                    text-transform: capitalize;

                    &:focus {
                        border-bottom: 3px solid $black;
                    }
                }

                .children-level {
                    a {
                        font-size: 1rem !important;
                    }
                }
            }

            ul > li > a > i {
                margin-left: 25px;
                font-size: 1.7rem;
                font-weight: 400;
                color: black;
            }

            .children-level {
                .nav-item {
                    padding: 0;

                    &.selected {
                        background-color: $grey-light-bg;
                        border-bottom: initial;
                    }

                    .nav-link {
                        &:hover, &:focus {
                            background-color: $grey-light-bg;
                            border-bottom: initial;
                        }
                    }
                }
            }
        }
    }
    nav .nav-secondary {
        border-top: 2px solid $black;
        border-bottom: 2px solid $black;
        padding-top: 16px;

        ul {
            flex-direction: column-reverse !important;
        }
    }

    #top-nav-main {
        ul.navbar-nav {
            .nav-link {
                white-space: normal;
            }
        }
    }
}

@media (max-width: 576px) {
    .container {
        max-width: 100%;
    }
}

#top-nav-main {
    .nav-secondary {
        ul.navbar-nav .nav-link {
            font-size: 0.875rem !important;
            line-height: 2rem;
            padding: 0;
        }
    }

    ul.navbar-nav .nav-link {
        font-size: 1rem !important;
        line-height: 2rem;
        padding: 0;
    }

}

.nav-item.child.desktop {
    &:not(:last-child) {
        margin-right: 1.5rem;
    }
}

.nav-item {
    &.child {
        &.desktop {
            position: relative;
        }

        &.mobile-tablet {
            margin-bottom: 16px;
            position: relative;

            &.sibling {
                margin-bottom: initial;
            }

            .children-level {
                top: 100%;
            }
        }
    }
}

@media (min-width: 992px) {
    #top-nav-main ul.navbar-nav .children-level .nav-link {
        font-size: 0.875rem !important;
    }

}

.mob-menu-header {
    padding: 6px 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mob-menu-title {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
    text-transform: capitalize;
}

.mob-menu-close {
    border: initial;
    background: initial;

    img {
        width: 18px;
        height: 18px;
    }

}
