@import "../library/colors";
@import "../library/responsive";

.search-result {
    margin-bottom: 3rem;
    a {
        text-decoration: none;
        h2 {
            color: $brand-secondary-orange;
        }
        .text {
            color: $black;
        }
    }

    .result-search-buttons {
        padding: 3rem 0rem;
    }
}
