@import "../library/colors";
@import "../library/responsive";

@media only screen and (max-width: $screen-sm) {
     .page-top-template {
        .image {
            .container {
                h1 {
                    font-size: 2.5rem !important;
                    text-align: center !important;
                    margin-left: auto;
                    margin-right: auto;
                    }
                }
            }
         .cover, cover1 {
            display: none !important;
            }
        }

}

@media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
     .page-top-template {
        .image {
            .container {
                h1 {
                    font-size: 3rem !important;
                }
                }
            }
        }
}


$image-height: 400px;



.page-top-template {

    .image {
        background-color: $grey-light-bg !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: $image-height;


        .container {
            height: $image-height;
            text-align:center;
            display: flex;
            align-items: center;

            h1 {
            max-width: 65%;
            color: $primary;
            font-size: 3.5rem;
            font-weight: 600;
                text-align: left;
                z-index:3

            }
        }
    }
    .cover {
        clip-path: polygon(76% 0, 100% 0, 100% 100%, 45% 100%);
        background-color: $primary;
        z-index: 2;
        margin-left: auto;
        width: 40%;
        opacity: 0.5;
        height: $image-height;
        position: absolute;
        right:0;
    }
    .cover1 {
        @extend .cover;
        width: 50%;
        clip-path: polygon(70% 0, 100% 0, 100% 100%, 43% 100%);
    }
    .coverAll {
        background-color: $secondary;
        z-index: 1;
        width: 100%;
        opacity: 1;
        overflow: hidden;
        height: $image-height;
        position: absolute;
        right:0;
    }
}
