@import "../library/responsive";
@import "../library/fonts";
@import "../library/colors";

.news {
    padding:5rem 0;

    .news-title {
        h2 {
            font-family: 'Winnipeg2', serif;
            font-weight: 400;
            text-align: center;
            font-size: 3rem;
            padding-bottom: 2rem;
            & a {
                text-decoration: none;
                color: $black;
                pointer-events: auto;

            }
        }
    }
    .news-cards {
        padding-bottom: 5rem;
        .card {
            border: .2px solid $black;
            min-width: 300px;
            max-width: 30%;
            margin-bottom: 2rem;
            padding: 0;
            display: inline-flex;
            margin-left: auto;
            margin-right: auto;
            text-decoration: none !important;
            color:$black !important;

            .card-img-top-wrapper {
                width: 100%;
                height: 250px;
                align-self: center;
                background-size: cover;
                background-color: $white;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 0;
                overflow: hidden;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .card-img-top {
                max-width: 100%;
                height: 250px;
                align-self: center;
                background-size: cover;
                background-color: $white;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 0;
            }

            .card-body {
                padding:1.5rem;
                .card-date {
                    p {
                        font-size: 0.8rem;
                    }
                }
                .card-title {
                    h3 {
                        font-size: 1rem;
                        font-weight: 800;
                    }
                }
                .card-title-line {
                    height: 2px;
                    width: 60px;
                    background-color: $primary;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 1rem;
                    padding-top: 1rem;
                }
            }
        }

    }
}
