.single-public-notice-page {
    .blog-page .blog-title h2 {
        color: $secondary;
    }
    .public-notice-btn-container {
        background-color: #FFFFFF;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
    }
    .public-notice-signature-container {
        background-color: #FFFFFF;
        padding: 1rem 2rem;
        .contacts-container {
            a {
                color: #212529;
            }
        }
    }
}
