/* FONTS
===========================
Important:
- Define default font families and font styles here.
- Design titles only using .d1, .d2... Not h1, h2...
- Use EM font sizes and adjust responsive by setting body font size.
- Default body font size is 16px.
*/

@import "responsive";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@font-face {
    font-family: 'Winnipeg1';
    src: url('../fonts/35F963_A.eot');
    src: url('../fonts/35F963_A.woff') format('woff'), url('../fonts/35F963_A.woff2') format('woff2'), url('../fonts/35F963_A.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Winnipeg1';
    src: url('../fonts/35F963_B.eot');
    src: url('../static/35F963_B.woff') format('woff'), url('../fonts/35F963_B.woff2') format('woff2'), url('../fonts/35F963_B.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Winnipeg1';
    src: url('../fonts/35F963_C.eot');
    src: url('../fonts/35F963_C.woff') format('woff'), url('../fonts/35F963_C.woff2') format('woff2'), url('../fonts/35F963_C.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Winnipeg1';
    src: url('../fonts/35F963_C.eot');
    src: url('../fonts/35F963_C.woff') format('woff'), url('../fonts/35F963_C.woff2') format('woff2'), url('../fonts/35F963_C.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Winnipeg1';
    src: url('../fonts/35F963_D.eot');
    src: url('../fonts/35F963_D.woff') format('woff'), url('../fonts/35F963_D.woff2') format('woff2'), url('../fonts/35F963_D.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Winnipeg1';
    src: url('../fonts/35F963_E.eot');
    src: url('../fonts/35F963_E.woff') format('woff'), url('../fonts/35F963_E.woff2') format('woff2'), url('../fonts/35F963_E.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Winnipeg1';
    src: url('../fonts/35F963_F.eot');
    src: url('../fonts/35F963_F.woff') format('woff'), url('../fonts/35F963_F.woff2') format('woff2'), url('../fonts/35F963_F.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Winnipeg2';
    src: url('../fonts/35F963_16.eot');
    src: url('../fonts/35F963_16.woff') format('woff'), url('../fonts/35F963_16.woff2') format('woff2'), url('../fonts/35F963_16.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Winnipeg2';
    src: url('../fonts/35F963_20.eot');
    src: url('../fonts/35F963_20.woff') format('woff'), url('../fonts/35F963_20.woff2') format('woff2'), url('../fonts/35F963_20.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Winnipeg2';
    src: url('../fonts/35F963_24.eot');
    src: url('../fonts/35F963_24.woff') format('woff'), url('../fonts/35F963_24.woff2') format('woff2'), url('../fonts/35F963_24.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Winnipeg2';
    src: url('../fonts/35F963_25.eot');
    src: url('../fonts/35F963_25.woff') format('woff'), url('../fonts/35F963_25.woff2') format('woff2'), url('../fonts/35F963_25.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../fonts/FiraSans-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../fonts/FiraSans-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../fonts/FiraSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../fonts/FiraSans-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../fonts/FiraSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}


$font-title: 'Winnipeg2', serif;
$font-content: 'Fira Sans', sans-serif;
$font-fancy: 'Winnipeg1', serif;

$baseFontSize : 15;

html, body {
    font-family: $font-content;
    //font-size: $baseFontSize - 2 * 1px;
    font-size: 1rem;
     @include breakpoint(lg) {
      //font-size: $baseFontSize * 1px;
    }
}
.row {
    margin-left:0 !important;
    margin-right:0 !important;
}


[class*="col-"] {
     padding-left:0 !important;
    padding-right:0 !important;
}

// Typekit loading transition.
// (Use or remove)
//body {
//    &:before {
//        content: "";
//        background-color: $white;
//        position: fixed;
//        top: 0; right: 0; bottom: 0; left: 0;
//        z-index: 10000;
//        transition: opacity ease 1000ms;
//        pointer-events: none;
//        -webkit-touch-callout: none;
//    }
//    .wf-active &:before, .wf-inactive &:before {
//        opacity: 0;
//    }
//}

h1, h2, h3, h4, h5, h6, .d1, .d2, .d3, .d4, .d5, .d6 {
    font-family: $font-title;
}
a { color: #ff6c37; }
a:hover { color: #ff6c37 }
* { outline: none }

.text-uppercase { text-transform: uppercase }
.text-capitalize { text-transform: capitalize }

.text-thin { font-weight: 100 }
.text-normal { font-weight: 400 }
.text-bold { font-weight: 700 }
.text-extrabold { font-weight: 900 }

.text-fancy { font-family: $font-fancy }
