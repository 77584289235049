.public-notice-page {
    &.page-events-container {
        .events .events-display .events-container .event-block .events-name {
            margin-bottom: initial !important;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .circle {
                display: none;
            }
        }
        .description {
            display: none;
        }
    }
}

.public-notice-page, .single-public-notice-page, .by-law-page, .financials-page {
    .public-notice-tag {
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 4px 16px;
        background-color: #BAA163;
        border-radius: 50px;
        span {
            font-style: italic;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }
}
.public-notice-tag {
    display: none;
}
