.by-law-page {
    .blog-page .blog-title h2 {
        color: $secondary;
        padding-top: 2rem;
    }
    .by-law-button-container {
        background-color: $white;
    }
    .by-law-signature-container {
        background-color: $white;
        padding: 16px 32px;
    }
    .by-law-files-container {
        background-color: $white;
        padding: 0 32px 16px 32px;
        a {
            display: block;
        }
    }
}
