@import "../library/colors";



.blog-page {
    padding: 3rem 0;
    .blog-image {
        width: auto;
        img {
            object-fit: cover;
            height: 250px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }

    .blog-image-partner {
        width: auto;
        img {
            object-fit: cover;
            max-height: 250px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }



    .copy-right-share {
        font-size: 0.8rem;
        padding-top: 0.5rem;
        .copy-right {
            display: block;
            float: left;
            .fa {
                margin-right: 0.5rem;
            }
        }
        .place {
            text-align: right;
        }
        .share {
            display: block;
            float: right;
            .fa {
                margin-left: 0.5rem;
                color: $brand-secondary-orange;
            }
        }
    }
    .blog-title {
        h2 {
            font-size: 3rem;
            color: $brand-secondary-orange;
            padding-top: 4rem;
            font-family: 'Winnipeg2', Fallback, serif;
            font-weight: 600;
        }
    }
    .blog-text {
        padding: 1rem 2rem;
        h3 {
            font-size: 1.5rem;
            font-weight: 600;
        }
        p {
            font-size: 1rem;
        }
    }

    .blog-quote {
        .quote-text {
            p {
                margin-top: 1rem;
                font-size: 1.5rem;
                font-family: 'Winnipeg2', Fallback, serif;
                display: inline-block;
                margin-left: 5rem;
                border-left: 2px solid $primary;
                padding-left: 3rem;
                &::before {
                    content: "";
                    position: absolute;
                    width: 100px;
                    border-bottom: 5px solid $primary;
                    transform: rotate(90deg);
                    margin-left: -99px;
                    margin-top: 90px;
                }
            }
        }
    }
}
.blog-page > .container {
    max-width: 920px;
}
.categories {
    padding-bottom: 10px;
    a {
        color: $black;
        cursor: pointer;
        font-weight: 600;

        &:hover {
            color: $primary;
            text-decoration: none !important;
        }

        &:not(:first-child) {
            margin-left:1rem;
        }
    }
}

.categories-container {
    margin: 1rem 0 1rem 0;
    border-left:2px solid $primary;
    .categories {
        padding:1rem;
    }
}


@media only screen and (max-width: $screen-md) {
    .blog-page {
        .blog-text {
            padding: 1rem 2rem !important;
        }
        .blog-title {
            h2 {
                font-size: 2rem;
            }
        }
    }
    .blog-page > .container {
        padding: 0;
    }
}
