@import "../library/colors";
@media only screen and (max-width: $screen-md) {
    .component-text-image-container {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
        .component-text-image {
            flex-direction: column;
            &.image-right {
                flex-direction: column-reverse;
            }
            .component-text {
                width: 100% !important;
                padding-left: 0!important;
                padding-right: 0!important;
                h2 {
                    padding-top: 2rem;
                }
            }
            .component-image {
                width: 100% !important;
            }


        }

    }
}




.component-text-image-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    .component-text-image {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        align-items: center;
        width: 100%;
        &.image-left {
             flex-direction: row;
            .component-text {
                padding-left: 10%;
                padding-right: 10%;

            }
        }
        &.image-right {
            flex-direction: row-reverse;
            .component-text {
                padding-left: 10%;
                padding-right: 10%;

            }
        }
        .component-text {
            width: 60%;
            .component-title {
                 h2 {
                    font-family: 'Winnipeg2', serif;
                    font-weight: 800;
                    text-align: left;
                    line-height: 2.8rem;
                    padding-bottom: 2rem;
                    color:#ff6c37;
                }
            }
            .text {
                    p {
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: left;
                    padding-bottom: 1rem;
                    }
                }
            .btn {
                margin-top: 2rem
                }
        }
        .component-image {
            width: 40%;
                img {
                    width: 100%;
                    height: 350px;
                    object-fit: cover;
                }
            }
    }

}

