@import "../library/colors";
@import "../library/responsive";

.blog-text-contained{
    background-color: $white;
    padding-top: 2rem;
    padding-bottom:2rem;
    padding-left:2rem;
    padding-right:3rem;
    h2 {
        color: $brand-secondary-orange;
        font-size: 3rem;
        font-family: 'Winnipeg2', Fallback, serif;
        font-weight: 600;
    }
    p {
        font-size: 1rem;
        margin-top: 2rem;
    }
    .btn {
        margin-top: 2rem;
    }
}

.blog-text {
    background-color: $white;
    padding-top: 2rem;
    padding-bottom:2rem;
    padding-left:2rem;
    padding-right:2rem;
    h2 {
        color: $brand-secondary-orange;
        font-size: 3rem;
        font-family: 'Winnipeg2', Fallback, serif;
        font-weight: 600;
    }
    p {
        font-size: 1rem;
        margin-top: 2rem;
    }
    .btn {
        margin-top: 2rem;
    }
}
