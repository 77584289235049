@import "library/colors";


.footer-menu .menu li.selected a.nav-link.footer {
    color: #ffc844;
   }

@media only screen and (max-width: $screen-sm) {
    .footer {
        .coltwo{
            column-count: 2;
            ul{
                margin:0;
                padding:0;
            }
        }

        .menu ul {
            flex-direction: column !important;
        }
        .social-network {
            align-items: center;
        }
        .container .contact-us {
            flex-direction: column !important;
            align-items: center !important;
            address {
                flex-direction: column !important;
                align-items: center !important;
                text-align: center;
                .address {
                    padding: 0 !important;
                }

            }
            .footer-logo {
                    margin:0 !important;
                }
        }

    }
 }

.footer {
    background-color: $secondary;

    .container {
        color: white;
        padding-top: 2rem;
        .footer-menu {
            display: flex;
            align-content: space-between;
            flex-direction: row;
            width: 100%;
            .nav-link {
                padding: .2rem 0.3rem;
            }
            .social-network {
                div {
                    padding-left: 0.5rem;
                }
            }
            .menu {
                margin-left: auto;
                ul {
                    display: flex;
                    flex-direction: row;
                    text-transform: capitalize;
                    list-style-type: none;
                    li {
                        a {
                            color:$white;
                            font-weight: 600;
                            &:hover {
                                color: $primary;

                            }
                        }
                        ul {
                            display: flex;
                            flex-direction: column;
                            padding-left: 0;
                            li {
                                max-width: 150px;
                                a {
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }

        }
        .footer-text{
            margin: auto;
            padding-bottom: 2rem;
        }
        .contact-us {
            display: flex;
            flex-direction: row;
            align-content: space-between;
            width: 100%;
            address {
                display: flex;
                flex-direction: row;
                align-content: space-between;
                .address {
                    h6{
                        color: $primary;
                        font-size: 1.25rem;
                        font-weight: 400;
                    }
                    h6 a {
                        color: inherit; /* Inherits the color from the h6 */
                        text-decoration: none; /* Removes underline if needed */
                    }
                    h6 a:hover {
                        text-decoration: underline; /* Optional: Add hover effect */
                    }
                    p {
                        color: $white;
                        font-size: 1rem;
                        font-weight: 400;
                    }
                    &:nth-of-type(2) {
                        padding-left: 3rem;
                    }
                }
            }
            .footer-logo {
                margin-left: auto;
                img {
                    width: 160px;
                }
            }
        }
        .line {
            background-color: white;
            height: 3px;
            width: 100%;
            margin-top: 2rem;
            margin-bottom: 3rem;
            display: block;
        }
        .copyright {
            padding-bottom: 1rem;
            padding-top: 3rem;
             text-align:center;
            p {
                color: #6C99AC;
                font-size: 0.8rem;
            }
        }
    }

    .social-network {
        .youtube, .twitter, .facebook{
            a{
                padding: 4px;
                display: block;
                background: white;
                width: 30px;
                height: 30px;
                i.fa-2x {
                    color: #253746;
                    font-size: 1.5em;
                }
            }
        }
        .youtube{
            a{
                padding-left: 3px;
            }
        }
    }

}

.newsletter {
    padding: 3rem;
    background-color: #E53F51;
    p {
        text-align: center;
        color: $white;
        padding-bottom: 1rem;
        font-family: 'Winnipeg2', Fallback, serif;
        font-size: 1.2rem;
    }
    .btn {
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 250px;
    }
}
footer {
    .address {
        a {
            color: white;
        }

    }
}

@media only screen and (min-width: 767px) and (max-width: 941px) {
    .footer .container .footer-menu .nav-link {
        font-size: 0.8rem;
    }
}
@media only screen and (min-width: 576px) and (max-width: 808px) {
    .footer {
        .container {
            .footer-menu {
                display: block;
                .menu {
                    ul {
                        display: inline-block;
                        columns: 2;
                        margin-top: 50px;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 575px) {
    .footer {
        .container {
            .footer-menu {
                display: block;
                .menu {
                    ul {
                        margin-top: 50px;
                    }
                }
            }
        }
    }
}


