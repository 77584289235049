.single-board-meeting-container {
    .blog-page .blog-title h2 {
        color: $secondary;
    }
    .board-meeting-btn-container {
        background-color: $white;
    }
    .board-meeting-files-container {
        background-color: $white;
        padding: 16px 32px;
    }
}
