@import "../library/colors";
.block-data-reports-summary {
    display: flex;
    flex-direction: row;
    align-content: space-around;
    flex-wrap: wrap;
    background-color: $grey-bg;
    padding: 5rem;
    .data-reports-summary {
        width: 250px;
        height: 250px;
        background-color: $white;
        padding: 2rem;
        margin: 2rem;
        box-shadow: 10px 10px;
        h3 {
            font-size: 1.3rem;
            color: $brand-secondary-orange;
            font-family: 'Winnipeg2', Fallback, serif;
            font-weight: 600;
        }
        p {
            font-size: 1rem;
        }
    }
}
