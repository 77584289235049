/* COLORS
===========================
Important: Always define primary color
*/

$black: #000;
$white: #fff;
$red:#EB0028;
$primary: #FFC844;
$secondary: #243746;
$brand-secondary-orange: #FF6C37;
$brand-secondary-blue: #95D4E9;
$brand-secondary-plum: #B51E83;
$brand-text-color: #1C2A37;
$border: #c1c1c1;
$grey-bg:#E8E8E8;
$grey-light-bg:#F4F4F4;
$blue2050-primary:RGB(0, 20, 78);
$blue2050-menu:RGB(3, 21, 56);
$plan2050-green:RGB(54, 205, 158);


//$color-secondary: #ff0000; (Use or remove)
//$color-extra:  #00ff00; (Use or remove)





/* SOCIAL MEDIA */

$facebook-color: #3b5999;
$twitter-color: #55acee;
$linkedin-color: #0077b5;
$youtube-color: #cd201f;
$pinterest-color: #cb2026;
$googleplus-color: #dd5044;
$instagram-color: #e4405f;


/* TEXT COLORS
=========================== */

.text-primary { color: $primary !important }
.text-white { color: $white !important }
.text-black { color: $black !important }


/* BACKGROUND COLORS
=========================== */

.bg-primary { background-color: $primary !important }
.bg-white { background-color: $white !important }
.bg-grey-light { background-color: $grey-light-bg!important }
.bg-grey { background-color: $grey-bg !important }
.bg-grey-dark { background-color: $border !important }
.bg-black { background-color: $black !important }
.bg-yellow { background-color: $primary !important }
