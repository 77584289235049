/* BUTTONS
===========================
Important:
- Place button variations in btn mixin
- You should be able to combine all variants without any issue
 */
/*
a, .btn, button {
    text-decoration: none;
    @include transition-md();
    &:focus, &:active {
        outline: none !important;
        text-decoration: none;
    }
}

.btn-default, .btn {
    font-family: $font-content;
    font-size: 14px;
    padding: 10px 50px;
    border-radius: 0;
    border: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

@mixin btn ($background, $color) {
    @extend .btn-default;
    background-color: $background;
    color: $color;
    &:hover, &:focus, &.active {
        background-color: lighten($background, 5%);
        box-shadow: 0 4px 30px -5px lighten($background, 5%);
        color: $color;
        &.btn-action .btn-action-icon {
            background-color: lighten($background, 15%);
        }
    }
    &:active, &:active:focus, &:active:hover {
        background-color: darken($background, 5%);
        box-shadow: 0 4px 15px -5px darken($background, 5%);
        color: $color;
        .btn-action-icon {
            background-color: $background;
        }
    }
    .open>&.dropdown-toggle {
        &, &:focus, &:active, &:focus {
            background-color: darken($background, 5%);
        }
    }
    &.btn-outline {
        background-color: transparent;
        color: $background;
        border: 2px solid $background;
        &:hover, &:focus, &.active {
            background-color: $background;
            box-shadow: 0 4px 30px -5px lighten($background, 5%);
            color: $color;
        }
        &:active, &:active:focus, &:active:hover {
            background-color: darken($background, 5%);
            box-shadow: 0 4px 15px -5px darken($background, 5%);
            color: $color;
        }
    }
    &.btn-sm {
        padding: 2px 40px;
    }
}

.btn-primary { @include btn($primary, $white) }
.btn-grey { @include btn($grey-light-bg, $primary) }
.btn-grey-dark { @include btn($grey-bg, $white) }
.btn-white { @include btn($white, $primary) }

.btn-full {
    width: 100%;
}

*/

@import "colors";
@import "fonts";

.btn {
    padding: 1.5rem !important;
    font-size: 1rem !important;
    border: none !important;
    text-transform: uppercase;
    font-weight: 800 !important;
    transition: transform 1s;
    border-radius: 0;
    &:hover {
        transform: scale(1.05);
    }
    &:focus {
        padding: 2rem;
        border: 1px solid $secondary !important;
    }
}

.btn-primary {
    background-color: $primary !important;
    color: $secondary !important;
    border-radius: 0;
}

.btn-primary-outline {
    background-color: transparent !important;
    color: $primary !important;
    outline-color: $primary !important;
    font-size: 1.5rem;
    padding: 0.2rem 1rem;

}

.button-events-search {
    margin-left: 0.75rem;
    outline: none;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    background-color: #e9ecef;
    transition: background-color 0.5s ease;
    border-radius: 0;
    &:hover, active, focus {
        background-color: $primary;

    }

}



