.anchor-tags-row {
    &.row {
        margin-right: -15px !important;
        margin-left: -15px !important;
    }

    .col {
        flex-basis: content;
        margin-bottom: 30px;
    }

    @media only screen and (min-width: 576px) {
        flex-basis: 0;
    }
    .btn-primary {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: initial !important;
        border: 1px solid #495057 !important;
        font-family: Winnipeg2,serif;
        font-style: italic;
        font-weight: 600 !important;
        font-size: 32px !important;
        line-height: 38px !important;
        color: #243746;
        text-transform: initial;
        &:hover {
            background-color: #FFFFFF !important;
            border: 1px solid #FFC844!important;
        }
    }
}
