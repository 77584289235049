@import "../library/colors";
@import "../library/responsive";
@import "../library/fonts";
.page-events-container {
    padding: 0 !important;
    background-color: $grey-light-bg;
    min-height: 80vh;
    .events {
        padding:4rem 0 4rem 0;
        .events-title {
            h2 {
                font-size: 2rem;
                font-weight: 400;
                font-family: 'Winnipeg2', Fallback, serif;
                text-align: center;
                padding-bottom: 2rem;
            }
        }
        .events-form {
            background-color: $grey-bg;
            padding: 1rem;
        }
        .events-display {
            .events-display-month {
                h3 {
                    font-size: 2rem;
                    font-family: 'Winnipeg2', Fallback, serif;
                    font-weight: 400;
                    text-align: center;
                    padding-top: 3rem;
                    padding-bottom: 0.5rem;
                }
                .line-under {
                    background-color: $primary;
                    height: 1px;
                    width: 100%;
                }
                .extra-line-under {
                    background-color: $primary;
                    height: 5px;
                    width: 10%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: -3px;
                }
            }
            .events-container {
                margin-top: 1rem;
                .event-block {
                    border: 1px solid $border;
                    padding: 1.5rem;
                    margin-bottom: 1rem;
                    background-color: $white;
                    .events-date {
                        margin-top: auto;
                        margin-bottom: auto;
                        .day-month {
                            display: flex;
                            .day-year-wrap {
                                margin-left: 10px
                            }
                            .day {
                                font-size: 2rem;
                                font-weight: 800;
                            }
                            .month {
                                display: inline-block;
                                font-size: 1rem;
                                vertical-align: middle;
                                margin-top: -10px;
                            }
                            .year {
                                font-weight: bold;
                                border-top: 1px dashed #ffc844;
                                display: flex;
                                padding-top: 5px;
                            }
                        }
                        .day-week {
                            p {
                                font-size: 1.5rem;
                                font-weight: 400;
                                margin-top: -5px;
                            }
                        }
                    }
                    .events-name {
                        h4 {
                            font-size: 1.5rem;
                            font-weight: 600;
                            display: inline;
                        }
                        .circle {
                            height: 15px;
                            width: 15px;
                            border-radius: 50%;
                            background-color: $brand-secondary-blue;
                            display: inline-block;
                            margin-left: 1rem;
                        }
                        .hours {
                            margin: 10px 0 -10px;
                            p {
                                font-size: 1.1rem;
                                .fa {
                                    color: $primary;
                                    margin-right: 0.7rem;
                                }
                            }
                        }
                        .place {
                            p {
                                font-size: 1.1rem;
                                .fa {
                                    color: $primary;
                                    margin-right: 0.5rem;
                                }
                            }
                        }
                        .description {
                            p {
                                font-size: 1rem;
                                font-weight: 400;
                            }
                        }
                        .social {
                            p {
                                font-size: 1rem;
                                font-weight: 400;
                                .fa {
                                    color: $primary;
                                    padding-left: 0.7rem;
                                }
                            }
                        }
                    }
                    .events-button {
                        margin-top: auto;
                        margin-bottom: auto;
                        .btn-primary {
                            width: 100%;
                            border: 2px solid #fff;
                            max-width: 200px;
                            margin: 10px 5px;
                        }
                        .line-break {
                            line-break: anywhere;
                        }
                        .resource_format {
                            font-size: 10px;
                            font-weight: normal;
                            font-style: italic;
                        }
                    }
                }
                .tag-name-block{
                    padding: 1.2rem;
                }
            }
        }
    }
    .btn {
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 760px) {
    .events-container {
        .tags-line {
            display: block;
            .label-tag {
                display: list-item;
                width: fit-content;
                margin-bottom: 5px;
            }
        }
    }
}
