.financials-page {
    .financials-filter-underline {
        border-top: 1px solid $primary;
        position: relative;
        top: -3px;
    }
    .page-events-container .events .events-display .events-display-month {
        .col-sm {
            &.active {
                border-bottom: 5px solid $primary;
            }
        }
        a {
            &:hover {
                text-decoration: initial;
            }
            color: $secondary;
            h3 {
                margin-bottom: initial;
                font-size: 24px;
                line-height: 38px;
                @media only screen and (max-width: 575px) {
                    padding-top: 2rem;
                }
            }
        }
    }
    .page-events-container .events .events-display .events-container .event-block .events-name .circle {
        display: none;
    }
    .events-container .event-block .events-name {
        margin-bottom: initial !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
