@import "../library/colors";
@import "../library/responsive";

@media only screen and (max-width: $screen-sm) {
    .component-events {
        margin-left: -90% !important;
    }
    .page-events-container .events .events-title h2 {
        font-size: 2rem;
        font-weight: 400;
        font-family: Winnipeg2, Fallback, serif;
        text-align: center;
        padding-bottom: 2rem;
        margin-top: 4rem;
    }
}


@media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
    .component-events {
        //margin: 45% auto !important
    }
}

@media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
    .component-events {
        //margin-left: -65% !important;
    }
}

@media only screen and (min-width: $screen-lg) and (max-width: $screen-xl) {
    .component-events {
        //margin-left: -50% !important;
    }
}

.events-section {
    background-color: $grey-bg;
    display: flex;
    flex-direction: row;
    padding: 10rem 0 10rem 0;
    align-items: center;
    justify-content: center;

    .component-image {
        .image {
            display: block;
            height: 500px;
            width: 600px;
            max-width: 98vw;
            align-self: center;
            background-size: cover;
            background-color: $grey-bg;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .component-events {
        //margin-left: -45%;
        margin-bottom: -5px;

        .carousel-homepage {
            position: relative;

            .carousel-inner {
                max-width: 500px;
                display: inline-block;

                .events {
                    background-color: $primary;
                    width: 500px;
                    height: 500px;
                    padding: 6rem 2rem 2rem 3rem;

                    .event-date {
                        .date-month-place {
                            display: flex;
                            flex-direction: row;
                            align-self: start;

                            .date {
                                span {
                                    font-size: 3rem;
                                    font-weight: 800;
                                    color: $black;
                                }
                            }

                            .month-place {
                                display: flex;
                                align-items: baseline;
                                p {
                                    font-size: 1rem;
                                    padding-left: 1rem;
                                }
                                .month {
                                    margin-top: 15px;
                                }
                                .place {
                                    margin-top: -15px;
                                }
                                .year {
                                    margin-left: 5px;
                                    padding-left: 5px;
                                    border-left: 1px dashed #000000;
                                    font-weight: bold;
                                }
                                .year2 {
                                    font-weight: bold;
                                    border-left: 1px dashed;
                                    padding-left: 5px;
                                }
                            }
                        }
                        .day {
                            margin-top: -25px;

                            p {
                                font-size: 2.4rem;
                            }
                        }
                    }
                    .event-name {
                        p:first-of-type {
                            font-size: 2rem;
                            font-weight: 800;
                            display: inline-block;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                        .event-dot {
                            height: 15px;
                            width: 15px;
                            background-color: $red;
                            border-radius: 50%;
                            display: inline-block;
                            margin-left: 10px;
                        }

                        p:nth-of-type(2) {
                            font-size: 1rem;

                            span:nth-of-type(2) {
                                padding-left: 5px;
                                padding-right: 5px;
                            }
                        }
                    }

                    a {
                        background-color: $black;
                        padding: 0.8rem !important;
                        font-size: 1rem !important;
                    }
                }
            }
        }

        .buttons-events {
            position: absolute;
            top: 25px;
            right: 25px;

            .btn-sm {
                display: inline-block;
                border: 1px solid $black !important;
                padding: 0.6rem 1rem 0.6rem 1rem !important;

                .fa {
                    font-size: 1rem;
                    padding: 0;
                }
            }
        }
    }
}

.events-container {
    .event-block {
        .events-name {
            padding-right: 10px !important;
        }

        .events-date {
            .card-img-top {
                max-width: 100%;
            }
        }
    }

    .tags-line {
        display: flex;
        margin-bottom: 10px;

        .label-tag {
            font-style: italic;
            padding: 2px 15px;
            background: #baa163;
            color: white;
            border-radius: 50px;
            margin: 0 6px;

            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
        }
    }
}

@media only screen and (max-width: 1117px) {

}

@media only screen and (max-width: 680px) {
    .events-section {
        padding: 0;

        .component-image {
            position: absolute;
        }
        .component-events {
            max-width: 282px;
            .carousel-homepage {
                .carousel-inner {
                    max-width: 100%;
                    .events {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .events-section {
        .component-events {
            margin-left: 0 !important;
        }
    }
}






















