    @import "../library/colors";
    .plan-2050-page {
        .block-data-reports-summary.plan2050 {
            background-color: $blue2050-primary !important;
            .data-reports-summary:nth-of-type(1) {
                box-shadow: 10px 10px $primary;
            }
            .data-reports-summary:nth-of-type(2) {
                box-shadow: 10px 10px $plan2050-green;
            }
            .data-reports-summary:nth-of-type(3) {
                box-shadow: 10px 10px $brand-secondary-orange;
            }
            .data-reports-summary:nth-of-type(4) {
                box-shadow: 10px 10px $brand-secondary-blue;
            }
            .data-reports-summary:nth-of-type(5) {
                box-shadow: 10px 10px $red;
            }
            .data-reports-summary:nth-of-type(6) {
                box-shadow: 10px 10px $brand-secondary-plum;
            }
        }

    .page-top {
        height: auto !important;
        .text {
            background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../static/img/1.jpg);
            background-size: cover;
            width: auto;
            height: 600px;
            background-color: $blue2050-primary;
            background-repeat: no-repeat;
            background-position: center;
            h1 {
                padding-left: 9rem;
                padding-top: 12rem;
                font-size: 4rem;
                font-weight: 600;
                font-family: 'Winnipeg2', Fallback, serif;
                color: $primary;
                max-width: 60%;
            }
            p {
                padding-left: 9rem;
                font-size: 2rem;
                font-weight: 400;
                color: $white;
                max-width: 50%;
                font-family: 'Winnipeg2', Fallback, serif;
            }
            a {
                padding-left: 9rem;
                font-size: 1rem;
                color: green;
                margin-top: 2rem;
                text-decoration: none;
                display: block;
            }
        }
    }

    .navbar-2050 {
        background-color: $blue2050-primary;
        padding: 0 3rem 0 0 !important;
        .navbar-brand {
            padding-left: 8rem;
            img {
                max-height: 34px;
            }
        }
        .slogan {
            padding-top: 18px;
            margin-left: -10px;
            p {
                font-size: 1rem;
                color: $white;
                font-family: 'Winnipeg2', Fallback, serif;
                font-weight: 400;
                border-left: 2px solid $primary;
                padding-left: 10px;
                &:nth-of-type(2) {
                    margin-top: -20px;
                }
            }
        }
        .navbar-nav {
            .nav-item {
                a {
                    color: $white;
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
        }
    }

    .sticky {
        position: fixed !important;
        top: 0;
        z-index: 3;

    }

    .carousel {
        background-color: $grey-light-bg;
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
        h2 {
            font-size: 2rem;
            font-weight: 600;
            font-family: 'Winnipeg2', Times, serif;
            text-align: center;
            color: $blue2050-primary;
            padding-bottom: 4rem;
        }
         .buttons {
                .prev, next {
                    margin-left: 17%;
                    outline: 1px solid $primary;
                    border: none !important;
                    &.disabled {
                        outline:1px solid $blue2050-primary !important;
                    }
                }
                .next {
                    margin-left: 60%;
                    outline: 1px solid $primary;
                     border: none !important;
                    &.disabled {
                        outline:1px solid $blue2050-primary !important;
                    }
                }
            }
        .controls {
           margin-top: -17px;
        }
        .frame {

            ul.block-data-reports-summary {
                background-color: transparent !important;
                white-space: nowrap;
                flex-wrap: nowrap;
                z-index: -1;

                li.data-reports-summary {
                    width: 300px;
                    white-space: normal;
                    flex-shrink: 0;
                    transition: all 0.2s ease-in-out;
                    list-style: none;
                    p {
                        font-size: 0.8rem;
                    }
                    h3 {
                        font-size: 1rem;
                    }
                    .read-more {
                        font-size: 0.8rem;
                    }
                    &.active {
                        transform: scale(1.5);
                        margin-left: 5rem;
                        margin-right: 5rem;
                    }
                }
            }
        }
    }
    /* Scrollbar */

    .scrollbar {
        height: 2px;
        background: $primary;
        line-height: 0;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .scrollbar .handle {
        width: 250px !important;
        height: 300%;
        background-color: $primary;
        cursor: pointer;
    }
}

/* Blog style changes  */
.blog-text h1 {
    font-family: 'Winnipeg2',Fallback,serif;
    font-weight: 600;
    font-size: 2.4rem;
    margin-top: 2rem;
    margin-bottom: 1.8rem;
    color: #243746;
}

.blog-text h2 {
    font-size: 1.9rem;
    font-family: Fira Sans,sans-serif;
    font-weight: 900;
    margin-top: 1.7rem;
    margin-bottom: 1rem;
    color: #243746;
}

.blog-page .blog-text h3 {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Fira Sans', sans-serif;
    margin-top: 1.4rem;
    margin-bottom: .7rem;
    color: #243746;
}

.blog-text h4 {
    font-size: 1.2rem;
    font-weight: 700;
    font-family: 'Fira Sans', sans-serif;
    margin-top: 1.1rem;
    margin-bottom: .5rem;
    color: #243746;
}

/* Size video iframes */
.video-iframe {
  width: 100%;
  height: calc(100vmax * .25);
  min-height: 175px;
}

@only screen and (max-width: 768px){

    .blog-text h1 {
        font-size: 1.8rem;
        margin-top: 2rem;
        margin-bottom: 1.8rem;
    }

    .blog-text h2 {
        font-size: 1.6rem;
        margin-top: 1.6rem;
        margin-bottom: .8rem;
    }

    .blog-page .blog-text h3 {
        font-size: 1.3rem;
        margin-top: 1.4rem;
        margin-bottom: .5rem;
    }

    .blog-text h4 {
        font-size: 1.1rem;
        margin-top: 1rem;
        margin-bottom: .3rem;
    }
}
