.component-data-report-extend {
    padding: 3rem 8rem 3rem 8rem;
    background-color: $grey-bg;
    .data-report-text {
        padding: 1rem 5rem 5rem 5rem;
        h2 {
            font-size: 2rem;
            font-weight: 600;
            font-family: 'Winnipeg2', Fallback, serif;
            color: $brand-secondary-orange;
        }
        p {
            font-size: 1rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }
    .data-set-box {
        padding-top: 2rem;
        display: flexbox;
        flex-direction: column;
        .data-set {
            width: 750px;
            background-color: $white;
            margin-left: auto;
            margin-right: auto;
            border: 1px solid $border;
            padding: 2rem;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            .data-set-text {
                flex: 1 0 75%;
                h3 {
                    font-size: 1.5rem;
                }
                p {
                    font-size: 1rem;
                }
            }
            .btn {
                flex: 0 0 25%;
                .fa {
                    padding-left: 1rem
                }
            }
        }
    }
}
