@import "../library/responsive";
@import "../library/fonts";

.btn-partners{
    background-color: #ffc844!important;
    color: #243746!important;
    border-radius: 0;
    padding: 0.2rem!important;
    font-size: 0.8rem!important;
    border: none!important;
    text-transform: uppercase;
    font-weight: 800!important;
    transition: transform 1s;
}

.btn-partners:hover{
    background-color:#243746!important;
    color:#ffc844!important;
    text-decoration: none;
}

.partners {

    padding:5rem 0;

    .partners-title {
        h2 {
            font-family: 'Winnipeg2', serif;
            font-weight: 400;
            text-align: center;
            font-size: 3rem;
            padding-bottom: 2rem;
            & a {
                text-decoration: none;
                color: $black;
                pointer-events: auto;

            }
        }
    }

    .partners-cards {
        padding-bottom: 5rem;
        .card {
            .hrefContainer {
                text-decoration: none;
                color: unset;
                cursor: pointer !important;
                pointer-events: auto;
                width: 100%;
                height: 100%;
            }

            .card-img-top {
                padding:2rem;
                width: auto !important;
                max-height: 150px;
                max-width: 100%;
            }
            .card-body {
                display: flex;
                flex-direction: column;
                .card-read-more {
                    margin-top: auto;
                }
            }
        }

    }
}
