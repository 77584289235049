.single-meeting-type-container {
    .go-back-link {
        display: inline-block;
        margin-top: 1.5rem;
    }
    .meeting-type-title {
        font-size: 2rem;
        font-weight: 400;
        font-family: Winnipeg2,Fallback,serif;
        text-align: center;
        padding-bottom: 2rem;
        margin-top: 1.5rem;
    }
    .meeting-type-description {
        margin-bottom: 1.5rem;
    }
    iframe {
        margin-bottom: 4rem;
    }
}
