.meetings-list-container {
    background-color: #f4f4f4;
    padding-bottom: 70px;

    .meetings-title {
        font-family: Winnipeg2,serif;
        font-weight: 400;
        text-align: center;
        font-size: 3rem;
        margin-bottom: 4rem;
        padding-top: 4rem;
    }
    .meetings-container {
        .meetings-category-container {
            margin-top: 70px;
            .meetings-category {
            font-family: Winnipeg2,Fallback,serif;
            font-weight: 600;
            font-size: 2rem;
            line-height: 2.3rem;
            margin-bottom: 40px;
        }
        .meetings-category-content {
            .meetings-type {
                display: flex;
                padding: 20px;
                border: 1px solid #C1C1C1;
                background: #FFF;
                margin-bottom: 32px;
                align-items: center;
                justify-content: flex-start;
                &:hover {
                    text-decoration: initial;
                    border: 1px solid #FFBD20;
                    background: #FFC844;
                }
                h4 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    display: inline;
                    color: #212529;
                    font-style: italic;
                    line-height: 1.9rem;
                    max-width: 87%;
                    margin-bottom: initial;
                }
                .circle {
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    background-color: #95d4e9;
                    display: inline-block;
                    margin-left: 1rem;
                    max-width: 13%;
                }
            }
        }
        }

    }

}
